import HashLoader from "react-spinners/HashLoader";
import classes from "./Teams.module.css";
import { useContext, useEffect, useState } from "react";
import UserProvider from "../../store/user-context";
import Team from "./Team";
import { toast } from "react-toastify";

const Teams = () => {
  const [fetchingTeam, setfetchingTeam] = useState(false);
  const [teamName, setTeamName] = useState("");
  const [teams, setTeams] = useState([]);
  const userCtx = useContext(UserProvider);

  const createTeam = () => {
    setfetchingTeam(true);
    fetch(`${userCtx.httpRequest}api/addTeam/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": userCtx.csrf,
      },
      credentials: "include",
      body: JSON.stringify({
        teamName: teamName,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if(data['flag']){
          toast("Team created successfully")
          setTeamName("")
          fetchTeams()
        }
      });
    setfetchingTeam(false);
  };

  

  const fetchTeams = () => {
    fetch(`${userCtx.httpRequest}api/getTeams/` , {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": userCtx.csrf,
      },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        let tempArray= []
        for(let team in data ){
          tempArray.push( { 'id' : data[team]['id'], 'teamName' : data[team]['teamName'] })
        }
        setTeams(tempArray);
      });

      return () =>{
        setTeams([]);
      }
  };

  useEffect(() => {
    fetchTeams();
  }, []);

  return (
    <div className="w-100">
      <div className={classes.container}>
        <div className={classes.addContactContainer}>
          <h1 className={classes.heading}>Create Group</h1>
          <div className="d-flex flex-row flex-wrap align-items-center w-100 justify-content-center gap-3 mb-3">
            <input
              className={classes.greenInput}
              value={teamName}
              onInput={(e) => setTeamName(e.target.value)}
            />
            {!fetchingTeam && (
              <button onClick={createTeam} className={classes.addButton}>
                Create
              </button>
            )}
            {fetchingTeam && (
              <HashLoader
                color="#42B883"
                className={classes.spinner}
                loading={true}
                size={25}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            )}
          </div>
        </div>

        <div>
            { teams && teams.map( (team) =>{
              return( <Team key={`t${team['id']}`} teamId={`${team['id']}`} name={team['teamName']} refresh={fetchTeams} />)
            } ) }
        </div>
      </div>
    </div>
  );
};

export default Teams;
