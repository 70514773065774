import React, { useState, useContext, useEffect } from "react";
import Login from "./components/Login/Login";
import UserProvider from "./store/user-context";
import { BrowserRouter , Routes, Route } from 'react-router-dom';
import Register from './components/Register/Register'
import Menu from "./components/Menu/Menu";
import { ToastContainer, toast } from "react-toastify";
import Logout from "./components/Login/Logout";
import Contacts from "./components/Contacts/Contacts";
import Teams from "./components/Teams/Teams";
import Settings from "./components/Settings/Settings";
import Cron from "./components/Cron";
import Classes from './App.module.css'
import About from "./components/UI/About";
import ForgotPassword from "./components/UI/ForgotPassword";
import ResetPassword from "./components/UI/ResetPassword";

function App() {
  const ctx = useContext(UserProvider);

  return (
    <BrowserRouter>
      <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      <Routes>
        <Route path="/update/gxtR34ptdf" element={!ctx.isLoggedIn && <Cron />} />
        <Route path="/*" element={!ctx.isLoggedIn &&  <Login></Login>  } />
        <Route path="/register" element={<Register />} />
        <Route path="/about" element={<About />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/reset/password/:token" element={<ResetPassword />} />
      </Routes>
      
      {ctx.isLoggedIn &&
      <main className="d-flex flex-row" style={{'background' : "#151929" ,'minHeight' : '100vh'}  }>
        <Menu></Menu>
        <Routes>
          <Route path="/*" element={ctx.isLoggedIn &&  <Contacts></Contacts> } />
          <Route path="/teams" element={ctx.isLoggedIn &&  <Teams></Teams> } />
          <Route path="/settings" element={ctx.isLoggedIn &&  <Settings></Settings> } />
          <Route path="/logout" element={ctx.isLoggedIn && <Logout></Logout>} />
        </Routes>
      </main>
      }
      
    </BrowserRouter>
  );
}

export default App;
