import classes from "./Team.module.css";
import { useContext, useState } from "react";
import UserProvider from "../../store/user-context";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faTrash,
  faPenToSquare,
  faClose,
  faFileLines
} from "@fortawesome/free-solid-svg-icons";

import ContactItem from "./ContactItem";
import Statistics from "./Statistics";
import TeamPapers from "./TeamPapers";

const Team = (props) => {
  const userCtx = useContext(UserProvider);
  const [contactList, setContactList] = useState([]);
  const [openModal, setOpenModal] = useState(false)
  const [openStatistics, setOpenStatistics] = useState(false)
  const [statistics, setStatistics] = useState([])
  const [showPapers, setShowPapers] = useState(false)
 
  const deleteTeam = () => {
    fetch(`${userCtx.httpRequest}api/deleteTeam/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": userCtx.csrf,
      },
      credentials: "include",
      body: JSON.stringify({
        id: props.teamId,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data["flag"]) {
          toast("Team removed");
          props.refresh();
        } else {
          toast("Oups..something went wrong, please try again later");
        }
      });
  };

  const getGroupContacts = () => {
    fetch(`${userCtx.httpRequest}api/getGroupContacts/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": userCtx.csrf,
      },
      credentials: "include",
      body: JSON.stringify({
        teamId: props.teamId,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if(Object.keys(data).length === 0){
          toast(`Oups..Seems like you don't have any contacts, try adding some first.`)
        }else{
          let tempArray = [];
          for (let contact in data) {
            tempArray.push({
              scholarId: data[contact]["scholarId"],
              fullName: data[contact]["fullName"],
              image: data[contact]["image"],
              isAdded: data[contact]["isAdded"],
            });
          }
          setContactList(tempArray);
          setOpenModal(true)
        }
      });

    return () => {
      setContactList([]);
    };
  };

  const getGroup = () =>{
    fetch( `${userCtx.httpRequest}api/getGroup/` , {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": userCtx.csrf,
      },
      credentials: "include",
      body: JSON.stringify({
        teamId: props.teamId,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if(Object.keys(data).length === 0){
          toast(`Oups..looks like ${props.name} doesn't have any contacts, try adding some first.`)
        }else{
          setOpenStatistics(true)
          setStatistics(data)
        }
      });

  }

  const handleClick = (e) =>{
    if(e.target.classList.contains('close')){
      setOpenModal(false)
    }
  }

  const handleStatsClick = (e) =>{
    if(e.target.classList.contains('close')){
      setOpenStatistics(false)
    }
  }

  const toggleShowPapers = () =>{
    setShowPapers(!showPapers)
  }

  return (
    <div>
      <div className={classes.container}>
        <div className={classes.title}>{props.name}</div>
        <div className={classes.buttonContainer}>
          <button onClick={deleteTeam}>
            <FontAwesomeIcon className={classes.colorRed} icon={faTrash} />
          </button>
          <button  onClick={()=>{getGroupContacts(); } }>
            <FontAwesomeIcon
              className={classes.colorGreen}
              icon={faPenToSquare}
            />
          </button>
          <button onClick={()=>{getGroup(); }} >
            <FontAwesomeIcon
              className={classes.colorGreen}
              icon={faChartLine}
            />
          </button>

          <button onClick={(e) =>  setShowPapers(!showPapers)}>
            <FontAwesomeIcon 
             className={classes.colorGreen}
             icon={faFileLines} />
          </button>

        </div>
      </div>

      <div onClick={(e) => handleClick(e)} className={`${classes.fixedContainer}  ${openModal ? classes.isOpen : ''} close`}>
        <div className={classes.groupContactsContainer}>
          <button onClick={()=>{ setOpenModal(false)} } className={classes.fixedContainerCloseButton}>
            <FontAwesomeIcon
              icon={faClose}
            />
          </button>
          {contactList &&
            contactList.map((contact) => {
              return (
                <ContactItem
                  key={` ${contact.scholarId} ${props.teamId}`}
                  teamId={props.teamId}
                  contact={contact}
                />
              );
            })}
        </div>
      </div>

      <div onClick={(e) => handleStatsClick(e)} className={`${classes.fixedContainer}  ${openStatistics ? classes.isOpen : ''} close`}>
        <div className={classes.groupContactsContainer}>
          <button onClick={()=>{ setOpenStatistics(false)} } className={classes.fixedContainerCloseButton}>
            <FontAwesomeIcon
              icon={faClose}
            />
          </button>

         

          <Statistics stats={statistics} />
        </div>
      </div>


      {/* <TeamPapers   teamId={props.teamId} onClose={toggleShowPapers}  isOpen={showPapers} /> */}
      <TeamPapers  name={props.name}  teamId={props.teamId} onClose={toggleShowPapers}  isOpen={showPapers} />


    </div>
  );
};

export default Team;
