import { useEffect, useState } from "react";
import classes from "./Statistics.module.css";
import OverviewStats from "../Overview/OverviewStats";
import {
  AreaChart,
  Area,
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Line
} from "recharts";

const Statistics = (props) => {
  const [stats, setStats] = useState([]);
  const [lastIndex, setLastIndex] = useState(false)
  const [citations, setCitations] = useState([])
  const [publications, setPublications] = useState([])
  const [hIndex, setHIndex] = useState([])

  const [publicationChartData, setPublicationChartData] = useState([])
  const [citationChartData, setCitationChartData] = useState([])

  useEffect(() => {
    if (props) {
      let tempStats = props.stats;
      if(Object.keys(props.stats).length !==0 ){
        setLastIndex((Object.keys(props.stats).length - 1))

      }
      setStats(props.stats);
    }
  }, [props.stats]);

  


  useEffect( () =>{
    if( lastIndex ){
      setCitations([
        { title: "Total citations", value: stats[lastIndex]["citations"] },
        {
          title: "Total citations of last 5 years",
          value: stats[lastIndex]["citations_last_five_years"],
        },
      ]);
      setHIndex([
        { title: "H-Index", value: stats[lastIndex]["h_index"] },
        {
          title: "H-Index of last 5 years",
          value: stats[lastIndex]["h_index_last_five_years"],
        },
      ]);
      setPublications([
        {
          title: "Total publications",
          value: stats[lastIndex]["publications_total"],
        },
        {
          title: "Total publications of last 5 years",
          value: stats[lastIndex]["publications_last_five_years"],
        },
      ]);


      // Pairnw thn souma apo ta publications kai sto array tempPublications kanw push to year kai ton arithmo apo ta publications
      // Meta gia kathe mia apo ta contact pou anoikoun sthn omada an den einai to lastindex pairnw ta publications pou eixan ekeinh 
      //Thn xronia kai ta prosthetw ston pinaka

      let tempPublications = [];
      let i =0;
      for (let year in stats[lastIndex]["publications_years"]) {
        tempPublications.push({
          year: year,
          Publications: parseInt(stats[lastIndex]["publications_years"][year]),
        });

        for(let stat in stats ){
          if(stat !=lastIndex ){
              tempPublications[i][stats[stat]["scholarId"]] = (stats[stat]["publications_years"][year]) ? stats[stat]["publications_years"][year] : 0
          }
        }
        i++
      }
      setPublicationChartData(tempPublications);

      let tempCitations = [];
      i =0;
      for (let year in stats[lastIndex]["citations_years"]) {
        tempCitations.push({
          year: year,
          Citations: parseInt(stats[lastIndex]["citations_years"][year]),
        });

        for(let stat in stats ){
          if(stat !=lastIndex ){
              tempCitations[i][stats[stat]["scholarId"]] = (stats[stat]["citations_years"][year]) ? stats[stat]["citations_years"][year] : 0
          }
        }
        i++
      }
      setCitationChartData(tempCitations);



    }

  },[lastIndex])



  const publicationsCharts = (
    // <ResponsiveContainer className={classes.chartContainer}  aspect={2}>
    <ResponsiveContainer width="98%" height={300}>
      <LineChart   data={publicationChartData}>
        <XAxis dataKey="year" />
        <YAxis domain={[0, "dataMax + 5"]} />
        <Tooltip  contentStyle={{backgroundColor: "#151929",color: "#42b883"}}  />
        
        <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
        <Line type="monotone" dataKey="Publications"  stroke="#42b883"  />

        { Object.keys(stats).map( (stat)=>{
          if(stat !== lastIndex){
            return(<Line type="monotone" name={stats[stat]['fullName']}  dataKey={stats[stat]['scholarId']} stroke={stats[stat]['color']}  dot={false} />)
          }
        } )
        }
      </LineChart>
    </ResponsiveContainer>
  );

  const citationCharts = (
    // <ResponsiveContainer className={classes.chartContainer}  aspect={2}>
    <ResponsiveContainer width="98%" height={300}>
      <LineChart   data={citationChartData}>
        <XAxis dataKey="year" />
        <YAxis domain={[0, "dataMax + 5"]} />
        <Tooltip  contentStyle={{backgroundColor: "#151929",color: "#42b883"}}  />
        
        <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
        <Line type="monotone" dataKey="Citations" stroke="#42b883" />

        { Object.keys(stats).map( (stat)=>{
          if(stat !== lastIndex){
            return(<Line type="monotone" name={stats[stat]['fullName']}   dataKey={stats[stat]['scholarId']} stroke={stats[stat]['color']}  dot={false} />)
          }
        } )
        }
      </LineChart>
    </ResponsiveContainer>
  );

  return (
    <div>
      {Object.keys(stats).map((stat) => {
        // console.log(stats[stat]);

        if(stats[stat].hasOwnProperty("fullName")){
          return (
            <div key={`item${stats[stat]['scholarId']}`} style={{ color: stats[stat]["color"] }}>
              <div className={`row my-3 g-0`}>
                <div className={`col-3  `}>
                  <div
                    className={`d-flex justify-content-center align-items-center h-100`}
                  >
                    <img className={classes.image} src={stats[stat]["image"]}></img>
                  </div>
                </div>
                <div className={`col`}>
                  <div
                    className={`d-flex justify-content-center align-items-center h-100 fs-5`}
                  >
                    {stats[stat]["fullName"]}
                  </div>
                </div>
              </div>
            
            </div>
          )
        }

        
      })}

      <div>
        <h1 className={classes.title}>Publications</h1>
        <div>
        {publicationsCharts}
        </div>
      </div>

      <div>
        <h1 className={classes.title}>Citations</h1>
        <div>
        {citationCharts}
        </div>
      </div>

      <div className={`row`}>
        <div className={`col-12 text-center`}>
          <OverviewStats data={citations} />
          <OverviewStats data={publications} />
          <OverviewStats data={hIndex} />
        </div>

      </div>
      
    </div>
  );
};

export default Statistics;
