import { useState, useContext, useEffect } from "react";
import {  toast } from "react-toastify";
import  UserProvider  from "../store/user-context";


const Cron = () =>{
  const userCtx = useContext(UserProvider);
  const [ result, setResult ] = useState("Fetching.....")
  const updateDB = () =>{
    
      fetch(`${userCtx.httpRequest}api/update/`, {
        credentials: "include",
        })
        .then((res) => res.json())
        .then((data) => {
          if ( data['flag'] ){
            setResult("All went  well")
            toast("All went  well")
          }else{
            setResult("Something went wrong")
            toast("Something went wrong")
          }
        })
        .catch((err) => {
          setResult("Something went wrong")
          toast("Something went wrong")
        });
  }

  useEffect( ()=>{
    updateDB()
  },[] )
 
  return(
    <div>
      {result}
    </div>
  )
}


export default Cron