import { useState, useContext, useRef, useEffect } from "react";
import UserProvider from "../../store/user-context";
import classes from "./Register.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import "react-toastify/dist/ReactToastify.css";

const Register = () => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [repassword, setRepassword] = useState("");
  const userCtx = useContext(UserProvider);
  const progressBar = useRef();
  const buttonRef = useRef();
  const navigate = useNavigate();

  const toggleButton = (value) => {
    buttonRef.current.disabled = value; // this disables the button
  };

  useEffect(() => {
    checkPassword();
  }, [password]);

  useEffect(() => {
    let flag = false;
    flag =
      username != "" &&
      checkEmail() &&
      checkPassword() &&
      password == repassword;
    // console.log(flag)

    toggleButton(!flag);
  }, [ email, username, password, repassword]);

  function checkEmail() {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(email.trim())) {
      return true;
    } else {
      return false;
    }
  }

  function checkPassword() {
    let score = 0;
    // 8 chars,  one upper, one special,one number
    var strongRegex = new RegExp("^(?=.*[A-Z])");
    if (strongRegex.test(password)) {
      score += 25;
    }
    strongRegex = new RegExp("^(?=.*[0-9])");
    if (strongRegex.test(password)) {
      score += 25;
    }
    strongRegex = new RegExp("^(?=.*[!@#$%^&*])");
    if (strongRegex.test(password)) {
      score += 25;
    }
    strongRegex = new RegExp("^(?=.{8,})");
    if (strongRegex.test(password)) {
      score += 25;
    }
    progressBar.current.style.width = `${score}%`;

    return score == 100;
  }

  const navToLogin = () => {
    navigate('/login');
  }

  const apiRegister = () => {

    fetch(`${userCtx.httpRequest}api/register/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": userCtx.csrf,
      },
      credentials: "include",
      body: JSON.stringify({
        email: email,
        username: username,
        password: password,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        toast(data['message']);
        if(data.flag){
          navToLogin()
          setEmail('')
          setUsername('')
          setPassword('')
          setRepassword('')
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ error: "Wrong username or password." });
      });
  };

  return (
    <div className={classes.bgDark}>
      <div className={classes.loginFormFlex}>
       
        <div className=" d-flex flex-column justify-content-center">
          <span className={`${classes.greenColor} ${classes.normalHeading} `}>
            Email
          </span>
          <input
            className={classes.greenInput}
            onBlur={(e) => setEmail(e.target.value)}
            defaultValue={email}
            type="text"
          ></input>
        </div>
        <div className=" d-flex flex-column justify-content-center">
          <span className={`${classes.greenColor} ${classes.normalHeading} `}>
            Username
          </span>
          <input
            className={classes.greenInput}
            onBlur={(e) => setUsername(e.target.value)}
            defaultValue={username}
            type="text"
          ></input>
        </div>
        <div className="d-flex flex-column justify-content-center position-relative">
          <div className="d-flex align-items-center justify-content-between">
            <span className={`${classes.greenColor} ${classes.normalHeading}`}>
              Password
            </span>
            <FontAwesomeIcon
              icon={faQuestion}
              className={classes.iconToolTip}
              data-tip="*Password must contain at least 8 characters, including one capital, one special character and a number"
            />
            <ReactTooltip className={classes.toolTip} />
          </div>
          <div className={classes.passwordProgressBar} ref={progressBar}></div>
          <input
            className={classes.greenInput}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></input>
          <input
            className={classes.greenInput}
            type="password"
            defaultValue={repassword}
            onBlur={(e) => setRepassword(e.target.value)}
          ></input>
        </div>
        <button
          className={classes.greenButton}
          onClick={apiRegister}
          ref={buttonRef}
        >
          Register
        </button>
        <button
          className={classes.greenButton}
          onClick={navToLogin}
        >
          Login
        </button>
      </div>
    </div>
  );
};

export default Register;
