import { useState, useContext, useEffect, useRef } from "react";
import UserProvider from "../../store/user-context";
import classes from "../Login/Login.module.css";
import rclasses from "../Register/Register.module.css";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import logo from '../../logo.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";

const ResetPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const userCtx = useContext(UserProvider);
  const [password, setPassword] = useState("");
  const [repassword, setRepassword] = useState("");
  const progressBar = useRef();


  function checkPassword() {
    let score = 0;
    // 8 chars,  one upper, one special,one number
    var strongRegex = new RegExp("^(?=.*[A-Z])");
    if (strongRegex.test(password)) {
      score += 25;
    }
    strongRegex = new RegExp("^(?=.*[0-9])");
    if (strongRegex.test(password)) {
      score += 25;
    }
    strongRegex = new RegExp("^(?=.*[!@#$%^&*])");
    if (strongRegex.test(password)) {
      score += 25;
    }
    strongRegex = new RegExp("^(?=.{8,})");
    if (strongRegex.test(password)) {
      score += 25;
    }
    progressBar.current.style.width = `${score}%`;

    return score == 100;
  }

  useEffect(() => {
    checkPassword();
  }, [password]);

  const apiReset = (event) => {
    event.preventDefault();

    if( !checkPassword() ){
      toast("Provide a stronger password.")
      return;
    }

    if( password !== repassword ){
      toast("Passwords do not match.")
      return;
    }

    fetch( `${userCtx.httpRequest}api/resetPassword/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": userCtx.csrf,
      },
      credentials: "include",
      body: JSON.stringify({
        token : token,
        password : password
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if(data['success'] && data['token_valid']){
          toast("Password changed successfully")
          navigate("/login")
        }else if ( !data['token_valid']){
          toast("Invalid token")
        }else{
          toast("Something went wrong, try again later.")
        }
      })
      .catch((err) => {
        toast("Something went wrong, try again later.")
      });
  };

  const navToLogin = () => {
    navigate('/login');
  }
  const navToPverview = () => {
    navigate('/contacts');
  }

  return (
    <div className={classes.bgDark}>
      <div className={classes.loginFormFlex} >
        <img src={logo}  className={classes.image}/>
        <h1 className={`${classes.greenColor} text-center fs-1`}>Reset Password</h1>
        <div className="d-flex flex-column justify-content-center position-relative">
          <div className="d-flex align-items-center justify-content-between">
            <span className={`${classes.greenColor} ${classes.normalHeading}`}>
              Password
            </span>
            <FontAwesomeIcon
              icon={faQuestion}
              className={classes.iconToolTip}
              data-tip="*Password must contain at least 8 characters, including one capital, one special character and a number"
            />
            <ReactTooltip className={classes.toolTip} />
          </div>
          <div className={rclasses.passwordProgressBar} ref={progressBar}></div>
          <input
            className={rclasses.greenInput}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></input>
          <input
            className={rclasses.greenInput}
            type="password"
            defaultValue={repassword}
            onBlur={(e) => setRepassword(e.target.value)}
          ></input>
        </div>
        
        <button className={classes.greenButton} onClick={apiReset}>Reset</button>
        
      </div>
    </div>
  );
};

export default ResetPassword;
