import React, { useEffect, useState } from "react";

const UserContext = React.createContext({
  csrf: '',
  isLoggedIn: false,
  onLogout : () =>{},
  onLogin : (email, password) =>{},
  getCSRF : () =>{},
  setCsrf : () =>{},
  getSession : () =>{},
  httpRequest : ''

})

export const UserProvider = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [csrf, setCsrf] = useState('')
  // const [httpRequest, setHttpRequest] = useState('/')
  const [httpRequest, setHttpRequest] = useState('/')

  const getCSRF = async () => {
    fetch(`${httpRequest}api/csrf/`, {
      credentials: "include",
    })
      .then((res) => {
        let csrfToken = res.headers.get("X-CSRFToken");
        setCsrf(csrfToken);
      })
      .catch((err) => {
      });
  };

  useEffect(() => {
    async function csrf(){
      await getCSRF();
    }
    csrf()


    getSession();
  },[])

  // useEffect(() => {
  //   getCSRF();
  //   getSession();
  // },[isLoggedIn])

  
  const getSession = () => {
    fetch(`${httpRequest}api/session/`, {
      credentials: "include",
    })
    .then((res) => res.json())
    .then((data) => {
      // console.log(data);
      if (data.isAuthenticated) {
        
        return setIsLoggedIn(true);
      } else {
        return setIsLoggedIn(false);
      }
    })
    .catch((err) => {
      console.log(err);
    });
  }
  

  const logoutHandler = () => {
    
    setIsLoggedIn(false)
  }

  const loginHandler = () => {
    
    setIsLoggedIn(true)
  }

  return(
    <UserContext.Provider value={{csrf: csrf ,isLoggedIn: isLoggedIn, httpRequest : httpRequest,setCsrf:setCsrf, onLogout: logoutHandler, onLogin: loginHandler, getCSRF : getCSRF, getSession  : getSession }}>
      {props.children}
    </UserContext.Provider>
  )
}

export default UserContext