import { NavLink } from "react-router-dom";
import classes from './Menu.module.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faAddressBook, faUser, faUsers, faGear, faPowerOff} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
const Menu = () => {

  const [menuIsShown, setMenuIsShown] = useState(true)
  const toggleMenu = () =>{
    setMenuIsShown(!menuIsShown)
  }

  return(
    <div className={classes.container}>
      <div className="d-flex flex-row align-items-center justify-content-between">
        <button className={classes.menuToggler} onClick={toggleMenu}>
          <FontAwesomeIcon  icon={faBars} />
        </button>
      </div>
      <ul className={[classes.menu,  menuIsShown && `${classes.menuHide}`].join(" ") }>
        <li> <NavLink to="/contacts" className={({ isActive }) =>isActive ? `${classes.currentMenu}` : undefined }><span><FontAwesomeIcon className={classes.menuIcon} icon={faAddressBook} /></span><span className={ menuIsShown && `${classes.hide}` }>Contacts</span></NavLink></li>
        <li> <NavLink to="/teams" className={({ isActive }) =>isActive ? `${classes.currentMenu}` : undefined }><span><FontAwesomeIcon className={classes.menuIcon} icon={faUsers} /></span><span className={ menuIsShown && `${classes.hide}` }>Teams</span></NavLink></li>
        <li> <NavLink to="/settings" className={({ isActive }) =>isActive ? `${classes.currentMenu}` : undefined }><span><FontAwesomeIcon className={classes.menuIcon} icon={faGear} /></span><span className={ menuIsShown && `${classes.hide}` }>Settings</span></NavLink></li>
        <li> <NavLink to="/logout" className={({ isActive }) =>isActive ? `${classes.currentMenu}` : undefined }><span><FontAwesomeIcon className={classes.menuIcon} icon={faPowerOff} /></span><span className={ menuIsShown && `${classes.hide}` }>Logout</span></NavLink></li>
      </ul>
    </div>
  )

}

export default Menu