import classes from "./ContactList.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine, faTrash, faFileLines } from "@fortawesome/free-solid-svg-icons";
import Overview from "../Overview/Overview";
import { useState } from "react";
import Papers from "./Papers";

const ContactList = (props) => {
  const [isWatching, setIsWatching] = useState(false);
  const [showPapers, setShowPapers] = useState(false)

  const toggleIsWatching = () =>{
    setIsWatching(!isWatching)
  }

  const toggleShowPapers = () =>{
    setShowPapers(!showPapers)
  }

  
  return (
    <div className={classes.container}>
      <div className="row my-2">
        <div className="col-12 col-md-3">
          <div className="d-flex h-100 text-center align-items-center justify-content-center">
            <img src={props.contact.image} className={classes.image}></img>
          </div>
        </div>
        <div className="col-12 col-md-6 ">
          <div className="d-flex h-100 text-center align-items-center justify-content-center">
            <div className={classes.contactInfo}>
              <span>{props.contact.fullName}</span>
              <span>{props.contact.scholarId}</span>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-3">
          <div className="d-flex h-100 text-center align-items-center justify-content-center">
            <div className={classes.contactListActionsContainer}>
              <button onClick={(e) => setIsWatching(!isWatching)}>
                <FontAwesomeIcon icon={faChartLine} />
              </button>

              <button onClick={(e) =>  setShowPapers(!showPapers)}>
                <FontAwesomeIcon icon={faFileLines} />
              </button>

              <button
                onClick={props.removeContact}
                id={props.contact.scholarId}
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          </div>
        </div>
      </div>
      
      <Papers  scholarId={props.contact.scholarId} onClose={toggleShowPapers}  isOpen={showPapers} />
      <Overview scholarId={props.contact.scholarId} onClose={toggleIsWatching}  isOpen={isWatching} />
    </div>
  );
};

export default ContactList;
