import { useState, useContext } from "react";
import UserProvider from "../../store/user-context";
import classes from "../Login/Login.module.css";
import { Link, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import logo from '../../logo.png'

const ForgotPassword = () => {
  const [username, setUsername] = useState("");
  const navigate = useNavigate();
  const userCtx = useContext(UserProvider);

  const apiLogin = (event) => {
    event.preventDefault();
    fetch( `${userCtx.httpRequest}api/forgotpassword/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": userCtx.csrf,
      },
      credentials: "include",
      body: JSON.stringify({
        user_input: username,
      }),
    })
      .then((res) => res.json())
      .then((data) => {

        if (data['email_send'] && data['user_found']) {
          toast("Email send successfully, please also check your spam.")
          setUsername("")
          navigate("/login")
        } else if( !data['user_found'] ) {
          toast("Please enter a valid username.")
        }else{
          toast("Something went wrong, try again later.")
        }
      })
      .catch((err) => {
        toast("Something went wrong, try again later.")
      });
  };

  const navToLogin = () => {
    navigate('/login');
  }
  const navToPverview = () => {
    navigate('/contacts');
  }

  return (
    <div className={classes.bgDark}>
      <div className={classes.loginFormFlex} >
        <img src={logo}  className={classes.image}/>
        <h1 className={`${classes.greenColor} text-center fs-1`}>Forgot Password</h1>
        <div className=" d-flex flex-column justify-content-center">
          <span className={`${classes.greenColor} ${classes.normalHeading} `}>Username</span>
          <input
            className={classes.greenInput}
            type="text"
            value={username}
            onInput={(e) => setUsername(e.target.value)}
          ></input>
        </div>
        
        <button className={classes.greenButton} onClick={apiLogin}>Send</button>
        <button className={classes.greenButton} onClick={navToLogin}>Login</button>
        <Link to={"/about"} className="text-center" style={{ color : "#42B883" }} >
          About
        </Link>
      </div>
    </div>
  );
};

export default ForgotPassword;
