import classes from "./ContactItem.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import UserProvider from "../../store/user-context";
import { useState, useContext } from "react";
import { toast } from "react-toastify";

const ContactItem = (props) => {
  const userCtx = useContext(UserProvider);
  const [isAdded, setIsAdded] = useState(props.contact.isAdded);

  const addToGroup = () => {

    fetch(`${userCtx.httpRequest}api/addToGroup/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": userCtx.csrf,
      },
      credentials: "include",
      body: JSON.stringify({
        teamId: props.teamId,
        scholarId: props.contact.scholarId,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        if( data['flag'] ){
          setIsAdded(true)
        }
        toast(data['message'])
      });

   
  };

  const removeFromGroup = () => {
    fetch(`${userCtx.httpRequest}api/removeFromGroup/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": userCtx.csrf,
      },
      credentials: "include",
      body: JSON.stringify({
        teamId: props.teamId,
        scholarId: props.contact.scholarId,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        if( data['flag'] ){
          setIsAdded(false)
        }
        toast(data['message'])
      });
  };

  return (
    <div className={`row my-2`}>
      <div className={`col-12 col-md-3 text-center`}>
        <img src={props.contact.image} className={classes.image}></img>
      </div>
      <div className={`col-12 col-md-6  text-center`}>
        <div className={classes.contactInfo}>
          <span>{props.contact.fullName}</span>
          <span>{props.contact.scholarId}</span>
        </div>
      </div>
      <div className={`col-12 col-md-3  text-center`}>
        <div className={classes.contactListActionsContainer}>
          {!isAdded && (
            <button onClick={addToGroup}>
              <FontAwesomeIcon className={classes.colorGreen} icon={faPlus} />
            </button>
          )}
          {isAdded && (
            <button onClick={removeFromGroup}>
              <FontAwesomeIcon className={classes.colorRed}  icon={faMinus} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactItem;
