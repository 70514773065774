import classes from "./Papers.module.css";
import { useContext, useEffect, useState } from "react";
import UserProvider from "../../store/user-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

const Papers = (props) => {
  const userCtx = useContext(UserProvider);
  const [papers, setPapers] = useState([]);
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(10)
  const [isLast, setIsLast] = useState(false)

  useEffect(() => {
    if( props.isOpen && !isLast ){
      fetchData()
    }
  }, [page,props.isOpen ])


  const fetchData = () => {
    fetch( `${userCtx.httpRequest}api/getPapers/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": userCtx.csrf,
      },
      credentials: "include",
      body: JSON.stringify({
        scholarId: props.scholarId,
        limit: limit,
        offset: limit * page,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        // let tempCitations = [];
        setIsLast(data["isLast"])
        let tempPapers = [];
        for (let paper in data["papers"]) {
          tempPapers.push(data["papers"][paper]);
        }

        if(papers.length == 0){
          setPapers( tempPapers );
        }else{
          setPapers( papers => [...papers, ...tempPapers])
        }
      })
      .catch((err) => {
        // userCtx.getCSRF()
      });

  };


  const calculatePercentage = (i) => {
    let currentPercentage = 1;
    let savedPercentage = 0;
    for (let j = 0; j < i; j++) {
        currentPercentage /= 2.1;
        savedPercentage += currentPercentage
    }
    return  Math.trunc(savedPercentage * 100);
  }

  let timer;
  const countProgress = (e) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      let elementHeight = e.target.scrollHeight - e.target.clientHeight;
      let scroll = e.target.scrollTop;
      let percent = 100 / (elementHeight / scroll);
  
      let dynamicPercent = 0
      dynamicPercent += calculatePercentage(page+1)
      if (percent > dynamicPercent) {
        setPage(page + 1);
      }
    },100)
  };


  const handleClick = (e) =>{
    if(e.target.classList.contains('close')){
      props.onClose()
    }
  }
  

  return (
    <div  onClick={(e) => handleClick(e)} 
      className={`${classes.fixedContainer} ${props.isOpen ? classes.isOpen : ""} close`}>
      <div className={classes.container} onScroll={(e) => countProgress(e)}>
      <button
        onClick={() => {
          props.onClose()
        }}
        className={classes.closeButton}
      >
        <FontAwesomeIcon icon={faClose} />
      </button>
        <div>
          {papers &&
            papers.map((paper, index) => {
              return (
                <div key={`${props.scholarId}${index}`} className={`${classes.paper} row g-0`}>
                  <div className="d-flex flex-column col-9">
                    <span className={`fs-5`}>Title : {paper["title"]}</span>
                    { paper["authors"] != '' &&
                       <span className={`${classes.smallText}`}>
                       Authors : {paper["authors"]}
                     </span>
                     }
                   
                    { paper["event"] != '' &&
                      <span className={`${classes.smallText}`}>
                      Event : {paper["event"]}
                    </span>
                     }
                  </div>
                  <div
                    className={`col-2 d-flex flex-column align-items-center`}
                  >
                    {paper["citedBy"] > 0 && (
                      <>
                        <span>Cited By</span>
                        <span>{paper["citedBy"]}</span>
                      </>
                    )}
                  </div>
                  <div
                    className={`col-1 d-flex flex-column align-items-center`}
                  >
                    {paper["year"] > 0 && (
                      <>
                        <span>Year</span>
                        <span>{paper["year"]}</span>
                      </>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
        
      </div>
    </div>
  );
};

export default Papers;
