
import classes from './OverviewStats.module.css'

const OverviewStats = (props) => {
  return(
    <ul className={classes.container}>
      { props.data &&  props.data.map( (item) => {

        return(
          <li key={item['title']}><span>{item['title']}  : </span><span>{item['value']}</span></li>
        )
      } )}
    </ul>
  )
}

export default OverviewStats