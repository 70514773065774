import { useContext, useEffect, useState} from "react";
import UserProvider from "../../store/user-context";
import classes  from './Contacts.module.css'
import { toast } from "react-toastify";
import ContactList from "./ContactList";
import HashLoader from "react-spinners/HashLoader";

const Contacts = () =>{
  const userCtx = useContext(UserProvider);
  const [scholarId, setScholarId] = useState('')
  const [contactList, setContactList] = useState([])
  const [ fetchingContact, setFetchingContact ] = useState(false)

  useEffect( () =>{
      getContacts()
  },[])


  const getContacts =() =>{
    fetch( `${userCtx.httpRequest}api/getContacts/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": userCtx.csrf,
      },
      credentials: "include",
      body: JSON.stringify({
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data)
        let tempArray= []
        for(let contact in data ){
          tempArray.push( { 'scholarId' : data[contact]['scholarId'], 'fullName' : data[contact]['fullName'], 'image' : data[contact]['image']  })
        }
        setContactList(tempArray)
      }).catch((err) => {

      });

    return () =>{
      setContactList([])
    }
  }

  const addContact = () =>{
    setFetchingContact(true)
    fetch(`${userCtx.httpRequest}api/addContact/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": userCtx.csrf,
      },
      credentials: "include",
      body: JSON.stringify({
        scholarId: scholarId,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        if( data['flag'] ){
          toast( data['message'] )
          setScholarId("")
          getContacts()
        }
        setFetchingContact(false)
      }).catch((err) => {
        setFetchingContact(false)
      });
  }


  const removeContact = (e)=> {
    

    fetch( `${userCtx.httpRequest}api/removeContact/` , {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": userCtx.csrf,
      },
      credentials: "include",
      body: JSON.stringify({
        scholarId: e.currentTarget.id
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if( data['flag'] ){
          toast( "Contact removed successfully" )
          getContacts()
        }else{
          toast( "Something went wrong, please try again later" )
        }
      }).catch((err) => {
        toast( "Something went wrong, please try again later" )
      });
  }

  
  return(
    <div className={classes.container}>
      <div className={classes.addContactContainer}>
        <h1  className={classes.heading}>Add contacts</h1>
        <div className="d-flex flex-row flex-wrap align-items-center w-100 justify-content-center gap-3 mb-3">
          <input className={classes.greenInput} onInput={e => setScholarId(e.target.value)} value={scholarId} />
          { !fetchingContact && <button onClick={addContact} className={classes.addButton}>Add contact</button> }
          { fetchingContact && <HashLoader
            color="#42B883"
            className={classes.spinner}
            loading={true}
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
          /> }
        </div>
        <div className={classes.contactListContainer}>
          {contactList &&  contactList.map( (contact) =>{
            return(<ContactList key={contact.scholarId} contact={contact} removeContact={removeContact} />)
          } )}
        </div>
      </div>
    </div>
  )

}

export default Contacts