import { useContext, useEffect} from "react";
import UserProvider from "../../store/user-context";

const Logout = () =>{
  const userCtx = useContext(UserProvider);
  
  useEffect( () => {
    fetch( `${userCtx.httpRequest}api/logout/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": userCtx.csrf,
      },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        if(data['logout']){
          userCtx.onLogout()
        }
      })
  },[])
  return(<div>Hello</div> )
}

export default Logout