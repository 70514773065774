import { useContext, useEffect, useState } from "react";
import UserProvider from "../../store/user-context";
import React, { PureComponent } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import classes from "./Overview.module.css";
import OverviewStats from "./OverviewStats";

const Overview = (props) => {
  const userCtx = useContext(UserProvider)
  const [citationChartData, setCitationChartData] = useState([])
  const [publicationChartData, setPublicationChartData] = useState([])
  const [citations, setCitations] = useState([])
  const [publications, setPublications] = useState([])
  const [hIndex, setHIndex] = useState([])

  useEffect(() => {
    if(props.isOpen && hIndex.length == 0 ){
      fetchData()
    }
  }, [userCtx.csrf, props.scholarId,props.isOpen]);


  const fetchData = () => {
    fetch(`${userCtx.httpRequest}api/overview/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": userCtx.csrf,
      },
      credentials: "include",
      body: JSON.stringify({
        scholarId: props.scholarId,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        let tempCitations = [];
        for (let year in data["citations"]["years"]) {
          tempCitations.push({
            name: year,
            citations: parseInt(data["citations"]["years"][year]),
          });
        }
        setCitationChartData(tempCitations);

        let tempPublications = [];
        for (let year in data["publications"]["years"]) {
          tempPublications.push({
            name: year,
            publications: parseInt(data["publications"]["years"][year]),
          });
        }

        setPublicationChartData(tempPublications);

        // console.log(data["citations"])
        setCitations([
          { title: "Total citations", value: data["citations"]["citations"] },
          {
            title: "Total citations of last 5 years",
            value: data["citations"]["citations_last_five_years"],
          },
        ]);
        setHIndex([
          { title: "H-Index", value: data["citations"]["h_index"] },
          {
            title: "H-Index of last 5 years",
            value: data["citations"]["h_index_last_five_years"],
          },
        ]);
        setPublications([
          {
            title: "Total publications",
            value: data["publications"]["publications_total"],
          },
          {
            title: "Total publications of last 5 years",
            value: data["publications"]["publications_last_five_years"],
          },
        ]);
      })
      .catch((err) => {
        // userCtx.getCSRF()
      });

    return () => {
      setCitationChartData([]);
    };
  };

  const citationsCharts = (
    // <ResponsiveContainer className={classes.chartContainer}  aspect={2}>
    <ResponsiveContainer width="98%" height={300}>
      <AreaChart  data={citationChartData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis domain={[0, "dataMax + 5"]} />
        <Tooltip  contentStyle={{backgroundColor: "#151929",color: "#42b883"}}  />
        <Area
          type="monotone"
          dataKey="citations"
          stroke="#42B883"
          fill="#5fe7aa59"
        />
      </AreaChart>
    </ResponsiveContainer>
  );

  const publicationsCharts = (
    // <ResponsiveContainer className={classes.chartContainer}  aspect={2}>
    <ResponsiveContainer width="98%" height={300}>
      <AreaChart  data={publicationChartData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis domain={[0, "dataMax + 5"]} />
        <Tooltip  contentStyle={{backgroundColor: "#151929",color: "#42b883"}}  />
        <Area
          type="monotone"
          dataKey="publications"
          stroke="#42B883"
          fill="#5fe7aa59"
        />
      </AreaChart>
    </ResponsiveContainer>
  );

  const handleClick = (e) =>{
    if(e.target.classList.contains('close')){
      props.onClose()
    }
  }

  return (
    <div onClick={(e) => handleClick(e)}
      className={`${classes.fixedContainer} ${props.isOpen ? classes.isOpen : ""} close`}
    >
      <div className={classes.container}>
      <button
        onClick={() => {
          props.onClose()
        }}
        className={classes.fixedContainerCloseButton}
      >
        <FontAwesomeIcon icon={faClose} />
      </button>
        <div className="row my-3 g-0">
          <div className="col-12 ">
            <h1 className={classes.title}>Citations</h1>
            {citationsCharts}
          </div>
          <div className="col-12 ">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <OverviewStats data={citations} />
              <OverviewStats data={hIndex} />
            </div>
          </div>
        </div>
        <div className="row g-0">
          <div className="col-12 ">
            <h1 className={classes.title}>Publications</h1>
            {publicationsCharts}
          </div>
          <div className="col-12 ">
            <div className="d-flex justify-content-center align-items-center">
              <OverviewStats data={publications} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
