import { useState, useContext, useRef, useEffect } from "react";
import UserProvider from "../../store/user-context";
import classes from "../Register/Register.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import { toast } from "react-toastify";
import { Link, useNavigate } from 'react-router-dom';
import "react-toastify/dist/ReactToastify.css";
import logo from '../../logo.png'

const About = () => {
  


  return (
    <div className={classes.bgDark}>
      <div className="w-50" >
       
        <div className=" d-flex flex-column justify-content-center align-items-center">
          <img src={logo} className="my-auto"  style={{ width : "300px" }}/>

          <h1 className={`${classes.greenColor} ${classes.normalHeading} `}>
            Scientometrics
          </h1>
          <p className={`${classes.greenColor} my-3`}>
            Scientometrics is a branch of science that deals with the measurement and evaluation of scientific research. It explores the various metrics that can be used to assess the quality of research and its impact on the research community and society at large. The aim of scientometrics is to improve the quality of research and to promote the development of scientific knowledge. Scientometrics often uses information technology to collect, analyze and present data.
          </p>
          <p className={`${classes.greenColor} my-3`}>
          The present application developed in the context of the thesis aims to expand the Google Scholar toolbox and provide a solution to the problems of organization and comparison. The functions of the application include collecting data from the Google Scholar platform, creating profiles of researchers and research groups, tracking publications, citations, h-index, as well as comparing data between different groups of researchers. By using this app, interested users can keep track of their scientific contacts and create groups with other researchers. Aiming to compare researchers within the group but also to compare between groups. This can lead to a deeper understanding of the latest developments in the research field and the development of new research ideas and proposals.
          </p>


        </div>
        <div className="mt-3 text-end">
          <Link className={`${classes.greenColor}`} to={"/"} >Back</Link>
        </div>
      </div>
    </div>
  );
};

export default About;
