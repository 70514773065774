import classes from "./Settings.module.css"
import { useState, useContext } from "react";
import UserProvider from "../../store/user-context";
import { toast } from "react-toastify";


const Settings = () =>{
  const userCtx = useContext(UserProvider);

  const deleteAccount = () =>{

    fetch(`${userCtx.httpRequest}api/deleteAccount/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": userCtx.csrf,
        },
        credentials: "include",
      })
        .then((res) => res.json())
        .then((data) => {
          if(data['flag']){
            toast("Your account deleted successfully")
            userCtx.onLogout()
          }else{
            toast("Something went wrong, please contact with an admin.")
          } 
        })
        .catch((err) => {
          toast("Something went wrong, please contact with an admin.")
        });
  }


  return(
    <div className={`d-flex flex-column  align-items-center w-100 ${classes.container} ` }>
        <h1>Settings</h1>
        <div className="d-flex align-items-center justify-content-center flex-wrap my-2" >
          <span className="me-3 fs-5">Delete account</span>
          <button onClick={deleteAccount} className={classes.deleteAccount}>Delete</button>
        </div>
    </div>
  )
}

export default Settings