import { useState, useContext } from "react";
import UserProvider from "../../store/user-context";
import classes from "./Login.module.css";
import { Link, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import logo from '../../logo.png'

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const userCtx = useContext(UserProvider);

  const apiLogin = (event) => {
    event.preventDefault();
    fetch( `${userCtx.httpRequest}api/login/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": userCtx.csrf,
      },
      credentials: "include",
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.response) {
          userCtx.setCsrf(data['csrf']);
          userCtx.onLogin();
          navToPverview()
        } else {
          toast("Wrong username or password.")
        }
      })
      .catch((err) => {
        toast("Wrong username or password.")
      });
  };

  const navToRegister = () => {
    navigate('/register');
  }
  const navToPverview = () => {
    navigate('/contacts');
  }

  return (
    <div className={classes.bgDark}>
      <div className={classes.loginFormFlex} >
        <img src={logo}  className={classes.image}/>
        <h1 className={`${classes.greenColor} text-center fs-1`}>Scholar Book</h1>
        <div className=" d-flex flex-column justify-content-center">
          <span className={`${classes.greenColor} ${classes.normalHeading} `}>Username</span>
          <input
            className={classes.greenInput}
            type="text"
            value={username}
            onInput={(e) => setUsername(e.target.value)}
          ></input>
        </div>
        <div className="d-flex flex-column justify-content-center">
          <span className={`${classes.greenColor} ${classes.normalHeading}`}>Password</span>
          <input
            className={classes.greenInput}
            type="password"
            value={password}
            onInput={(e) => setPassword(e.target.value)}
          ></input>
        </div>
        <button className={classes.greenButton} onClick={apiLogin}>Login</button>
        <button className={classes.greenButton} onClick={navToRegister}>Register</button>
        <Link to={"/forgotpassword"} className="text-center" style={{ color : "#42B883" }} >Forgot password </Link>
        <Link to={"/about"} className="text-center" style={{ color : "#42B883" }} >
          About
        </Link>
      </div>
    </div>
  );
};

export default Login;
